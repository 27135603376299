.content {
  width: 100%;
  height: 60vh;
  background: url("../assets/imgs/background_img.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.backdrop {
  width: 100%;
  height: 60vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
}


@media only screen and (max-width: 950px) {
  .content{
    
    height: 600px;
  }

  .backdrop {
    height: 600px;
  }
  
}


.backdrop p {
  font-size: 16px;
}

.backdrop h1 {
  font-size: 50px;
  line-height: 50px;
  letter-spacing: 0;
}

.service_article {
  max-width: 900px;
}
