.side-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 10px;
  box-shadow: 0px 10px 50px 0px rgb(17 17 17 / 4%);
}

.side-banner div {
  width: 400px;
}

.side-banner div h5 {
  font-size: 22px;
  line-height: 1.2;
  font-weight: 700;
  color: #131c21;
}

.side-banner div p {
  font-weight: 400;
  font-size: 15px;
  line-height: 1.7;
  color: rgba(19, 28, 33, 0.6);
}

.side-banner .tb-container {
  display: flex;
}

.side-banner .tb-container .form-control {
  background: #f3f5f6;
  padding: 0 70px 0 20px !important;
  border: none;
  border-radius: 0;
}

.side-banner .tb-container .btn {
  background: #2557c8;
  border: none;
  height: 50px;
  width: 100px;
}

.services {
  background: rgba(248, 249, 250);
  padding: 40px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.services .subtitle {
  background: #2557c8;
  width: 150px;
  padding: 4px 2px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 22px 0px 22px 0px;
  color: white;
  text-align: center;
}

.services .title {
  margin-top: 8px;
  text-align: center;
  line-height: 1.2em;
  font-weight: 700;
  color: #131c21;
  font-style: normal;
  font-size: 45px;
}

.services .image-cards {
  display: flex;
  margin-top: 36px;
}

.services .image-cards div:hover {
  transform: translate(-20px, -20px);
  box-shadow: 20px 20px 5px rgba(255, 0, 0, 0.4);
  border: 5px solid #2557c8;
  transition: 1s;
  cursor: pointer;
}

.services .image-cards div {
  width: 400px;
  margin-right: 26px;
  background: white;
  padding: 30px 14px;
}

.services .image-cards div img {
  width: 100%;
  height: 250px;
}

.services .image-cards div h3 {
  font-size: 22px;
  line-height: 1.2;
  font-weight: 700;
  color: #131c21;
  margin: 16px 2px;
}

.services .text-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 34px;
}

.services .text-container p:first-child {
  font-weight: 400;
  font-size: 15px;
  line-height: 1.7;
  color: rgba(19, 28, 33, 0.6);
  font-style: normal;
  margin-right: 22px;
}

.services .text-container p:nth-child(2) {
  font-size: 16px;
  line-height: 1.15;
  letter-spacing: 1.28px;
  font-family: "Poppins";
  font-weight: 400;
  color: #ff3f39;
}

.video-container {
  padding: 64px 104px;
  display: flex;
  background: rgba(248, 249, 250);
}

.video-container div:first-child {
  width: 40%;
  min-width: 700px;
}

.video-container div:nth-child(2) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 100px;
}

.video-container div:nth-child(2) h1 {
  font-size: 45px;
  line-height: 1.2;
  font-weight: 700;
  color: #131c21;
  font-style: normal;
}

.video-container div:nth-child(2) p {
  margin-bottom: 0;
  font-weight: inherit;
  color: inherit;
  margin-top: 0;
  line-height: inherit;
  width: 70%;
  margin-top: 18px;
}

.video-container div:first-child iframe {
  width: 100%;
  height: 400px;
  border: none;
}

.video-container .deli-car {
  position: absolute;
  right: 340px;
  margin-top: 420px;
}

.inquiry-container {
  display: flex;
  padding-top: 250px;
  background: rgba(248, 249, 250);
  padding-bottom: 120px;
  padding-left: 230px;
}

.inquiry-container div {
  flex: 1;
}

.inquiry-container div:first-child .form-card {
  width: 80%;
  margin-left: 10%;
  margin-top: 10px;
  background: white;
  padding: 44px 20px;
  text-align: center;
  box-shadow: 1px 1px 10px rgb(176, 176, 176);
}

.inquiry-container div:first-child .form-card h1 {
  text-align: center;
  line-height: 1.2em;
  font-weight: 700;
  color: #131c21;
  font-style: normal;
  font-size: 35px;
  margin-bottom: 22px;
}

.inquiry-container div:first-child .form-card .form-control {
  background: #f3f5f6;
  padding: 15px 20px;
  border: none;
  border-radius: 0;
  margin-bottom: 18px;
}

.inquiry-submit-btn {
  padding: 20px 35px !important;
  width: 80%;
  border: 1px solid #2557c8 !important;
  background: #2557c8 !important;
}

.inquiry-container div:nth-child(2) .inquiry-right-info {
  flex: 1;
  margin-top: 58px;
}
.inquiry-container .row {
  width: 100%;
}
.inquiry-container div:nth-child(2) .inquiry-right-info h6 {
  text-align: left;
  color: #131c21;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.2em;
}

.inquiry-container div:nth-child(2) .inquiry-right-info h1 {
  padding: 20px 5px;
  width: 60%;
  font-size: 45px;
  line-height: 1.2;
  font-family: "Poppins";
  font-weight: 700;
  color: #131c21;
  font-style: normal;
}

.inquiry-container div:nth-child(2) .inquiry-right-info p {
  font-size: 16px !important;
  text-align: left;
  color: rgba(19, 28, 33, 0.8);
  font-weight: normal;
  line-height: 1.56em;
  width: 60%;
}

.inquiry-container div:nth-child(2) .inquiry-right-info h4 {
  margin-top: 70px;
  font-size: 25px;
  line-height: 1.2;
  font-weight: 700;
  color: #131c21;

  font-style: normal;
}

.icon-list {
  padding-top: 10px;
}

.icon-list .icon {
  width: 50px;
  background: #2557c8;
  color: white;
  margin-right: 20px;
  line-height: 40px;
}

.inquiry-right-info .icon-list .desc {
  margin-top: 12px;

  margin-left: 80px;
}

.social-list {
  display: flex;
  margin-top: 100px;
}

.social-list span {
  margin-left: 10px;
}

.blog-section {
  padding: 55px 220px;
  display: flex;
  position: relative;
  margin-left: 100px;
  margin-right: 100px;
}

.blog-section .blog-item {
  width: 400px;
  margin-right: 20px;
  position: relative;
  top: 0px;
}

.blog-section .avatar {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.blog-section .blog-action {
  margin-top: 34px;
  display: flex;
}

.blog-section .blog-action p {
  margin-left: 20px;
  margin-top: 5px;
}

.accomplish {
  background: rgba(248, 249, 250);
  padding: 40px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.accomplish .subtitle {
  background: #2557c8;
  width: 200px;
  padding: 8px 12px;
  border-radius: 22px 0px 22px 0px;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}

.accomplish .title {
  margin-top: 8px;
  text-align: center;
  line-height: 1.2em;
  font-weight: 700;
  color: #131c21;
  font-style: normal;
  font-size: 45px;
}

.numbers-container {
  display: flex;
  padding: 34px 254px;
}

.numbers-container div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.numbers-container div h1 {
  font-size: 45px;
  line-height: 1.2;
  margin-top: 16px;
  font-weight: 700;
  color: #131c21;
  font-style: normal;
}

.numbers-container div h2 {
  font-size: 25px;
  line-height: 1.2;
  font-weight: 700;
  color: #131c21;
  font-style: normal;
}

.numbers-container div p {
  font-weight: 400;
  font-size: 15px;
  line-height: 1.7;
  color: rgba(19, 28, 33, 0.6);
  font-style: normal;
  width: 80%;
}

.talkings {
  padding: 34px 70px;
  background: rgba(248, 249, 250);
  padding-bottom: 120px;
}
.talkings .label {
  background: white;
  width: 700px;
  padding: 50px 24px;
  border-left: 4px solid #2557c8;
}

.talkings .label p {
  font-size: 25px;
  line-height: 1.2;
  font-family: "Poppins";
  font-weight: 700;
  color: #131c21;
  font-style: normal;
}

.talkings .avatar {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  margin-right: 40px;
}

.talkings .talking {
  margin-right: 25px;
  margin-left: 25px;
}

.talkings .bytalking {
  display: flex;
  flex-direction: row;
  padding: 16px 4px;
  align-items: center;
}

.talkings h6 {
  font-size: 18px;
  line-height: 1.2;
  font-weight: 700;
  color: #131c21;
  font-style: normal;
}

.talkings p {
  font-size: 14px;
  line-height: 1.2;

  font-style: normal;
}

.talkings .talking-box {
  display: flex;
  justify-content: space-around;
}

.talkings .one {
  display: none;
}

.blog-section-container-mobile {
  display: none;
}

@media only screen and (max-width: 1750px) {
  .video-container .deli-car {
    display: none;
  }
}

@media only screen and (max-width: 1450px) {
  .services .image-cards {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .services .image-cards div {
    margin-bottom: 20px;
  }

  .video-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 20px;
  }

  .video-container div:first-child {
    width: 800px;
    margin-bottom: 40px;
  }

  .video-container div:first-child iframe {
    width: 80%;
    height: 420px;
    border: none;
    margin-left: 10%;
  }

  .video-container div:nth-child(2) p {
    width: 100%;
  }

  .inquiry-container {
    display: flex;
    padding-top: 100px;
    padding-bottom: 120px;
    padding-left: 0 !important;

    align-items: center;
    justify-content: center;
  }

  .inquiry-container div {
    width: 80%;
  }

  .inquiry-container div:first-child .form-card {
    width: 80% !important;
    padding: 44px 20px;
  }

  .inquiry-container div:nth-child(2) .inquiry-right-info {
    margin-left: 44px;
  }

  .inquiry-container div:nth-child(2) .inquiry-right-info h1 {
    width: 90%;
  }

  .inquiry-container div:nth-child(2) .inquiry-right-info p {
    width: 90%;
  }

  .inquiry-container .social-list {
    margin-left: 30px;
  }

  .numbers-container {
    padding: 24px 100px;
  }

  .talkings .two {
    display: none;
  }

  .talkings .one {
    display: block;
  }
}

@media only screen and (max-width: 1250px) {
  .side-banner {
    flex-direction: row;
  }
  .side-banner .tb-container {
    display: flex;
  }
  .side-banner div {
    margin-bottom: 18px;
  }

  .video-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 10px;
  }

  .video-container div:first-child {
    width: 400px;
    margin-bottom: 40px;
  }

  .inquiry-container {
    display: flex;
    padding-top: 100px;
    padding-bottom: 120px;
    padding-left: 0 !important;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .inquiry-container div {
    width: 90%;
  }

  .inquiry-container div:first-child .form-card {
    width: 80% !important;
    padding: 44px 10px;
  }

  .inquiry-container div:nth-child(2) .inquiry-right-info {
    margin-left: 25px;
  }

  .inquiry-container div:nth-child(2) .inquiry-right-info h1 {
    width: 90%;
  }

  .inquiry-container div:nth-child(2) .inquiry-right-info p {
    width: 90%;
  }

  .inquiry-container .social-list {
    margin-left: 10px;
  }

  .blog-section-container .blog-section {
    display: flex;
    flex-wrap: wrap;
    margin-left: 5px;
    margin-right: 5px;
    padding: 0px;
    justify-content: center;
  }

  .blog-section-container .blog-section .blog-item {
    width: 250px;
  }
}

@media only screen and (max-width: 950px) {
  .video-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 10px;
  }

  .video-container div:first-child {
    width: 600px;
    margin-bottom: 40px;
  }

  .video-container div:first-child iframe {
    width: 60%;
    height: 400px;
    border: none;
    margin-left: 10%;
  }

  .video-container div:nth-child(2) {
    margin-left: 5px;
  }
  .video-container div:nth-child(2) p {
    width: 100%;
  }

  .inquiry-container {
    display: flex;
    padding-top: 100px;
    padding-bottom: 120px;
    padding-left: 0 !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .inquiry-container div {
    width: 80%;
  }

  .inquiry-container div:first-child .form-card {
    width: 70% !important;
    padding: 44px 10px;
  }

  .inquiry-container div:nth-child(2) .inquiry-right-info {
    margin-left: 35px;
  }

  .inquiry-container div:nth-child(2) .inquiry-right-info h1 {
    width: 90%;
  }

  .inquiry-container div:nth-child(2) .inquiry-right-info p {
    width: 90%;
  }

  .inquiry-container .social-list {
    margin-left: 10px;
  }

  .numbers-container {
    flex-direction: row;
    padding: 34px 154px;
  }

  .numbers-container div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .talkings .two {
    display: none;
  }

  .talkings .one {
    display: block;
  }

  .blog-section-container .blog-section {
    display: flex;
    flex-wrap: wrap;
    margin-left: 30px;
    margin-right: 30px;
    padding: 0px;
    justify-content: center;
  }

  .blog-section-container .blog-section .blog-item {
    width: 340px;
    margin-bottom: 10px;
    top: 0px;
  }
}

@media only screen and (max-width: 850px) {
  .inquiry-container div {
    width: 100%;
  }

  .blog-section-container {
    display: none;
  }

  .blog-section-container-mobile {
    display: block;
  }

  .blog-section-container-mobile .blog-section {
    display: flex;
    flex-wrap: wrap;
    margin-left: 10px;
    margin-right: 10px;
    padding: 0px;
    justify-content: center;
  }

  .blog-section-container-mobile .blog-section .blog-item {
    width: 550px;
    margin-bottom: 10px;
    top: 0px;
  }
}

@media only screen and (max-width: 650px) {
  .side-banner {
    flex-direction: column;
  }

  .talkings .label {
    background: white;
    width: 200px;
    padding: 50px 24px;
    border-left: 4px solid #2557c8;
  }
  .numbers-container {
    flex-direction: column;
    padding: 34px 154px;
  }
}

@media only screen and (max-width: 950px) {
  .talkings {
    background: rgba(248, 249, 250);
    padding-bottom: 180px;
    margin: 0px;
    padding: 0px 35px;
  }

  .talkings .label {
    background: white;
    width: 320px;
    padding: 50px 24px;
    border-left: 4px solid #2557c8;
  }

  .talkings .label p {
    font-size: 19px;
    line-height: 1.2;
    font-family: "Poppins";
    font-weight: 700;
    color: #131c21;
    font-style: normal;
  }

  .talkings .talking {
    margin-right: 10px;
    margin-left: 10px;
  }

  .video-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .video-container div:first-child {
    width: 30%;
    margin-bottom: 40px;
    margin-left: 150px;
  }

  .video-container div:first-child iframe {
    width: 50%;
    margin-left: 14%;
    height: 240px;

    border: none;
  }

  .video-container div:nth-child(2) {
    padding-left: 0px;
  }

  .video-container div:nth-child(2) p {
    width: 100%;
  }

  .inquiry-submit-btn {
    padding: 20px 35px !important;
    width: 80%;
    border: 1px solid #ff3f39 !important;
    background: #ff3f39 !important;
    margin-right: 10%;
  }
}
